<template>
  <div class="inclusion-section">
    <div class="text-center">
      <div class="font-36">Inclusions</div>
    </div>
    <div class="inclusion-section-form pt-3">
      <!-- basic section -->
      <div class="basic-section font-22">
        <div class="basic-title">Basic :</div>
        <div class="basic-content pt-3">
          <div v-for="bas in basic" :key="bas.key" class="checkbox-section">
            <b-form-group>
              <b-form-checkbox-group
                id="checkbox-group-2"
                v-model="detail.inclusions.basic"
                stacked
              >
                <b-form-checkbox
                  class="font-14"
                  :value="{
                    name: bas.name,
                    basic: bas._id
                  }"
                  >{{ bas.name }}</b-form-checkbox
                >
              </b-form-checkbox-group>
            </b-form-group>
          </div>
        </div>
        <div>
          <b-form-group>
            <label class="font-14">Remarks :</label>
            <b-form-input id="Size" v-model="detail.inclusions.basicRemark" required></b-form-input>
          </b-form-group>
        </div>
      </div>

      <!-- Special section -->
      <div class="special-section font-22 pt-4">
        <div class="special-title">Special :</div>

        <div class="special-content pt-3">
          <div v-for="spe in special" :key="spe.key" class="checkbox-section">
            <b-form-group>
              <b-form-checkbox-group id="checkbox-group-2" v-model="tempSpecial" stacked>
                <b-form-checkbox
                  class="font-14"
                  :value="{
                    special: spe._id,
                    name: spe.name
                  }"
                  >{{ spe.name }}</b-form-checkbox
                >
              </b-form-checkbox-group>
            </b-form-group>
            <b-form-group>
              <b-form-input
                id="Size"
                v-model="spe.comment"
                placeholder="comment"
                required
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div class="d-flex justify-content-center pt-5">
          <div>
            <b-button variant="success" @click="saveInclusion()">Save</b-button>
          </div>
          <div class="ml-3">
            <b-button variant="danger">Cancel</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import service from "@/service/apiservice";
import global from "@/service/global";
export default {
  // props: ["detail"],
  data() {
    return {
      detail: {
        inclusions: {
          basic: [],
          special: [],
          basicRemark: "",
          specialRemark: "",
          progressPage: "",
          progressCompleted: ""
        }
      },
      basic: [],
      special: [],
      display: false,
      show: false,
      showButton: false,
      selected: [],
      tempSpecial: [],
      comment: "",
      hotelId: ""
    };
  },
  created() {
    service.getInclusions(
      {
        type: "Basic"
      },
      basicResult => {
        this.basic = basicResult.data;
      }
    );
    this.getOneHotel();
  },
  methods: {
    getOneHotel() {
      this.hotelId = global.getHotel();
      service.getOneHotel(this.hotelId, data => {
        if (data.code === 200) {
          this.detail = data.data;

          service.getInclusions(
            {
              type: "Special"
            },
            specialResult => {
              this.special = specialResult.data;

              this.tempSpecial = this.detail.inclusions.special;
              _.each(this.tempSpecial, sppp => {
                const index = _.findIndex(this.special, ["_id", sppp.special]);
                this.special[index].comment = sppp.comment;
                delete sppp.comment;
                delete sppp._id;
              });
            }
          );

          _.each(this.detail.inclusions.basic, function(n) {
            delete n._id;
          });
          _.each(this.detail.inclusions.special, function(n) {
            delete n._id;
          });
        }
      });
    },
    saveInclusion() {
      if (this.detail.progressCompleted == true) {
        this.detail.progressPage = "covid";
      } else {
        this.detail.progressPage = "inclusion";
      }

      this.detail.inclusions.special = [];
      _.each(this.special, data => {
        _.each(this.tempSpecial, temp => {
          if (data._id == temp.special) {
            this.detail.inclusions.special.push({
              special: data._id,
              name: data.name,
              comment: data.comment
            });
          }
        });
      });

      service.updateHotelAdmin(this.detail, this.hotelId, saveResult => {
        this.$toaster.success("Details saved.");
        this.showButton = true;
      });
    }
  }
};
</script>
<style lang="scss">
.inclusion-section {
  .custom-checkbox .custom-control-label::before {
    border-radius: 0px;
  }
  .custom-control {
    margin: 5px 0px;
  }
  .checkbox-control {
    border-radius: 0px;
  }
}
</style>
