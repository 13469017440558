<template>
  <div class="policies-section">
    <div class="faq-title font-32">
      <div class="text-center">Policies</div>
      <div class="policy-details">
        <b-form>
          <b-col md="6">
            <div>
              <b-form-group>
                <label class="font-14">Terms &amp; Conditions*</label>
                <b-form-textarea
                  :state="validateState('payment')"
                  aria-describedby="input-payment-live-feedback"
                  name
                  id
                  cols="16"
                  rows="1"
                  v-model="$v.detail.policies.payment.$model"
                ></b-form-textarea>
                <b-form-invalid-feedback class="font-10" id="input-payment-live-feedback">
                  Terms and Conditions is required
                </b-form-invalid-feedback>
              </b-form-group>
            </div>

            <div>
              <b-form-group>
                <label class="font-14">Cancellations*</label>
                <b-form-textarea
                  cols="16"
                  rows="1"
                  :state="validateState('cancellations')"
                  aria-describedby="input-cancellations-live-feedback"
                  id="Cancellation"
                  v-model="$v.detail.policies.cancellations.$model"
                ></b-form-textarea>
                <b-form-invalid-feedback class="font-10" id="input-cancellations-live-feedback">
                  Cancellations is required
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div>
              <b-form-group>
                <label class="font-14">
                  Check-in &amp; Check-out*
                </label>
                <div>
                  <b-form-timepicker
                    v-model="$v.detail.policies.checkIn.$model"
                    :state="validateState('checkIn')"
                    aria-describedby="input-checkIn-live-feedback"
                    id="checkin"
                    placeholder="Check-in Time"
                  ></b-form-timepicker>

                  <b-form-invalid-feedback class="font-10" id="input-checkIn-live-feedback">
                    CheckIn is required
                  </b-form-invalid-feedback>

                  <b-form-timepicker
                    v-model="$v.detail.policies.checkOut.$model"
                    :state="validateState('checkOut')"
                    aria-describedby="input-checkOut-live-feedback"
                    id="checkOut"
                    placeholder="Check-out Time"
                    class="mt-2"
                  ></b-form-timepicker>
                  <b-form-invalid-feedback class="font-10" id="input-checkOut-live-feedback">
                    CheckOut is required
                  </b-form-invalid-feedback>
                </div>
              </b-form-group>
            </div>

            <div>
              <b-form-group>
                <label class="font-14 mr-5">Pets*</label>
                <b-form-select
                  v-model="$v.detail.policies.pets.$model"
                  :state="validateState('pets')"
                  aria-describedby="input-pets-live-feedback"
                  :options="options"
                ></b-form-select>
                <b-form-invalid-feedback class="font-10" id="input-pets-live-feedback">
                  Pets is required
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div>
              <b-form-group>
                <label class="font-14 mr-5">Max. Occupancy in each room*</label>
                <b-form-input
                  type="number"
                  :state="validateState('maxOccupancy')"
                  aria-describedby="input-maxOccupancy-live-feedback"
                  id="maxOccupancy"
                  v-model="$v.detail.policies.maxOccupancy.$model"
                ></b-form-input>
                <b-form-invalid-feedback class="font-10" id="input-maxOccupancy-live-feedback">
                  Max Occupancy is required
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div>
              <b-form-group>
                <label class="font-14 mr-5">Force Majeure*</label>
                <b-form-textarea
                  :state="validateState('froceMajure')"
                  cols="16"
                  rows="1"
                  aria-describedby="input-froceMajure-live-feedback"
                  id="froceMajure"
                  v-model="$v.detail.policies.froceMajure.$model"
                ></b-form-textarea>
                <b-form-invalid-feedback class="font-10" id="input-froceMajure-live-feedback">
                  Force Majure is required
                </b-form-invalid-feedback>
              </b-form-group>
            </div>

            <div>
              <b-form-group>
                <label class="font-14">Payment Terms*</label>

                <b-form-textarea
                  :state="validateState('terms')"
                  cols="16"
                  rows="1"
                  aria-describedby="input-terms-live-feedback"
                  id="terms"
                  v-model="$v.detail.policies.terms.$model"
                ></b-form-textarea>
                <b-form-invalid-feedback class="font-10" id="input-terms-live-feedback">
                  Payment Terms is required
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
          </b-col>
          <div class="d-flex justify-content-center pt-5">
            <div>
              <b-button variant="success" @click="saveData()">Save</b-button>
            </div>
            <div class="ml-3">
              <b-button variant="danger">Cancel</b-button>
            </div>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import service from "@/service/apiservice";
import { required } from "vuelidate/lib/validators";
import global from "@/service/global";

export default {
  // props: ["detail"],
  data() {
    return {
      detail: {
        policies: {},
        progressPage: "",
        progressCompleted: ""
      },

      showButton: false,

      selected: "",

      options: [
        { value: "Allowed", text: "Allowed" },
        { value: "Not Allowed", text: "Not Allowed" },
        { value: "Upon Request", text: "Upon Request" }
      ],
      hotelId: ""
    };
  },
  validations: {
    detail: {
      policies: {
        payment: {
          required
        },
        cancellations: {
          required
        },
        checkIn: {
          required
        },
        checkOut: {
          required
        },

        pets: {
          required
        },
        maxOccupancy: {
          required
        },
        froceMajure: {
          required
        },
        terms: {
          required
        }
      }
    }
  },
  created() {
    this.getOneHotel();
  },
  methods: {
    getOneHotel() {
      this.hotelId = global.getHotel();
      service.getOneHotel(this.hotelId, data => {
        if (data.code === 200) {
          this.detail = data.data;
        }
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.detail.policies[name];

      return $dirty ? !$error : null;
    },
    saveData() {
      this.$v.detail.policies.$touch();
      if (this.$v.detail.policies.$anyError) {
        return;
      }
      return new Promise((resolve, reject) => {
        if (this.detail.progressCompleted == true) {
          this.detail.progressPage = "covid";
        } else {
          this.detail.progressPage = "policies";
        }
        service.updateHotelAdmin(this.detail, this.hotelId, result => {
          if (result) {
            this.$toaster.success("Details saved.");
            resolve();
          } else {
            reject();
          }
        });
      });
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/scss/variables";
.policies-section {
  .btn-outline-primary {
    color: $gray;
    border-color: $gray;
    padding: 0.375rem 5.55rem;
    &:hover {
      background-color: $white;
    }
  }
  .btn-primary {
    color: $gray;
    background-color: $white;
    border-color: $gray;
  }
}
</style>
