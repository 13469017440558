<template>
  <div class="welcome-content">
    <div class="welcome-first-section">
      <div>
        <div class="text-center">
          <div class="font-36">Welcome</div>
          <div class="font-22">
            Let's start by telling something about the property
          </div>
        </div>
        <div>
          <b-form>
            <!-- Property info -->
            <div>
              <div class="font-26 py-3">Property Info</div>
              <b-row>
                <!-- Property Name -->
                <b-col md="12">
                  <div>
                    <b-form-group>
                      <label class="font-14">
                        Property Name*
                      </label>
                      <b-form-input
                        id="input-about-1"
                        v-model="details.name"
                        :state="validateState('name')"
                        aria-describedby="input-about-live-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-about-live-feedback">
                        Name of hotel is required.
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="4">
                  <!-- Star Rating -->
                  <div>
                    <b-form-group>
                      <label class="font-18">Star Rating*</label>
                      <b-form-select
                        v-model="details.starRating"
                        :state="validateState('starRating')"
                        aria-describedby="input-starRating-live-feedback"
                        :options="options"
                      ></b-form-select>
                      <b-form-invalid-feedback id="input-starRating-live-feedback">
                        hotel's star rating is required
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                </b-col>
                <b-col md="4">
                  <!-- Property Type -->
                  <div>
                    <b-form-group>
                      <label class="font-18">Property Type*</label>
                      <!-- <b-form-input id="input-2" v-model="detail.hotelType" required></b-form-input> -->
                      <!-- <multiselect
                        deselect-label="Can't remove this value"
                        multiple
                        label="name"
                        :options="property"
                        :searchable="true"
                        :allow-empty="false"
                      ></multiselect> -->
                      <multiselect
                        v-model="details.hotelType"
                        deselect-label="Can't remove this value"
                        track-by="_id"
                        multiple
                        name="_id"
                        label="name"
                        :options="property"
                        :searchable="true"
                        :allow-empty="false"
                      ></multiselect>
                      <b-form-invalid-feedback id="input-propertyType-live-feedback">
                        Select your property type
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                </b-col>
                <b-col md="4">
                  <!-- Property Name -->
                  <div>
                    <b-form-group>
                      <label class="font-18">Total Area of Property* </label>
                      <b-input-group append="sq mt">
                        <b-form-input
                          id="input-2"
                          v-model="details.areaOfProperty"
                          :state="validateState('areaOfProperty')"
                          aria-describedby="input-areaOfProperty-live-feedback"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-areaOfProperty-live-feedback">
                          Total Area of Property is required.
                        </b-form-invalid-feedback>
                      </b-input-group>
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
            </div>
            <!-- Description -->
            <div>
              <b-form-group>
                <label>Description</label>
                <div>
                  <!-- <textarea
                    name=""
                    id=""
                    cols="80"
                    rows="5"
                    placeholder="Description"
                    v-model="details.hotelDesc"
                  ></textarea> -->

                  <tinymce
                    id="description1"
                    :other_options="option"
                    v-model="details.hotelDesc"
                  ></tinymce>
                </div>
              </b-form-group>
            </div>
            <!-- Room info -->
            <div>
              <div class="font-26 py-3">Inventory</div>
              <b-row>
                <b-col md="6">
                  <!-- No. of Rooms: -->
                  <div>
                    <b-form-group>
                      <label class="font-18">No. of Rooms*</label>
                      <b-form-input
                        id="input-2"
                        v-model="details.NoOfRooms"
                        :state="validateState('NoOfRooms')"
                        aria-describedby="input-NoOfRooms-live-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-NoOfRooms-live-feedback">
                        Number of Rooms is required.
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                </b-col>
                <b-col md="6">
                  <!-- No. of Guests: -->
                  <div>
                    <b-form-group>
                      <label class="font-18">No. of Guests*</label>
                      <b-form-input
                        id="input-2"
                        v-model="details.NoOfGuest"
                        :state="validateState('NoOfGuest')"
                        aria-describedby="input-NoOfGuest-live-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-NoOfGuest-live-feedback">
                        Number of Guest is required.
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
            </div>
            <!-- location info-->
            <div>
              <div class="font-26 py-3">Location Info</div>
              <b-row>
                <b-col md="4">
                  <!-- Address -->
                  <div>
                    <b-form-group>
                      <label class="font-18">Address*</label>
                      <b-form-input
                        id="input-2"
                        v-model="details.address"
                        :state="validateState('address')"
                        aria-describedby="input-address-live-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-address-live-feedback">
                        Address is required.
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                </b-col>
                <b-col md="4">
                  <!-- Country -->
                  <div>
                    <b-form-group>
                      <label class="font-18">Country*</label>
                      <!-- <b-form-input id="input-2" v-model="detail.country" required></b-form-input> -->
                      <v-select
                        v-model="details.country"
                        :options="allCountry"
                        label="text"
                        return-object
                        @input="getState(details.country)"
                      ></v-select>
                      <div
                        v-if="
                          !$v.details.country.required &&
                            $v.details.country.$error &&
                            $v.details.country.$touch
                        "
                        class="error-text py-1"
                      >
                        Country is required
                      </div>
                    </b-form-group>
                  </div>
                </b-col>

                <b-col md="4">
                  <!-- State -->
                  <div>
                    <b-form-group>
                      <label class="font-18">State*</label>
                      <!-- <b-form-input id="input-2" v-model="detail.state" required></b-form-input> -->
                      <v-select
                        :options="state"
                        label="text"
                        v-model="details.state"
                        return-object
                        @input="getCity(details.country, details.state)"
                      ></v-select>
                      <div
                        v-if="
                          !$v.details.state.required &&
                            $v.details.state.$error &&
                            $v.details.state.$touch
                        "
                        class="error-text py-1"
                      >
                        State is required
                      </div>
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="4">
                  <!-- City -->
                  <div>
                    <b-form-group>
                      <label class="font-18">City*</label>
                      <!-- <b-form-input id="input-2" v-model="detail.city" required></b-form-input> -->
                      <v-select
                        v-model="details.city"
                        :options="city"
                        label="text"
                        return-object
                      ></v-select>
                      <div
                        v-if="
                          !$v.details.city.required &&
                            $v.details.city.$error &&
                            $v.details.city.$touch
                        "
                        class="error-text py-1"
                      >
                        City is required
                      </div>
                    </b-form-group>
                  </div>
                </b-col>
                <b-col md="4">
                  <!-- Pin Code -->
                  <div>
                    <b-form-group>
                      <label class="font-18">Pin Code</label>
                      <b-form-input id="input-2" v-model="details.pinCode"></b-form-input>
                      <!-- :state="validateState('pinCode')"
                        aria-describedby="input-pinCode-live-feedback" -->
                      <!-- <b-form-invalid-feedback id="input-pinCode-live-feedback">
                        Pincode is required.
                      </b-form-invalid-feedback> -->
                    </b-form-group>
                  </div>
                </b-col>
                <b-col md="4">
                  <div>
                    <b-form-group>
                      <label>Zone of India</label>

                      <!-- <b-dropdown :text="selected2 ? selected2.text : 'Zone of India'">
                        <b-dropdown-item
                          :disabled="option.disabled"
                          @click="selectedStatus(option)"
                          v-for="option in zone"
                          :key="option.value"
                          v-model="details.zoneOfIndia"
                        >
                          <div class="text">
                            {{ option.text }}
                          </div>
                        </b-dropdown-item>
                      </b-dropdown> -->

                      <b-form-select v-model="details.zoneOfIndia" :options="zone"></b-form-select>
                    </b-form-group>
                  </div>
                </b-col>
                <b-col md="4">
                  <div class="pt-1">
                    <b-form-group>
                      <label>World</label>
                      <br />
                      <!-- <b-dropdown :text="selected3 ? selected3.text : 'World'">
                        <b-dropdown-item
                          :disabled="option.disabled"
                          @click="selectedWorld(option)"
                          v-for="option in world"
                          :key="option.value"
                          v-model="details.world"
                        >
                          <div class="text">
                            {{ option.text }}
                          </div>
                        </b-dropdown-item>
                      </b-dropdown> -->
                      <b-form-select v-model="details.world" :options="world"></b-form-select>
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
            </div>
            <!-- distance info-->
            <div>
              <div>
                <b-form-group>
                  <label class="font-18">Distance From Airport*</label>

                  <b-row>
                    <b-col>
                      <b-form-input
                        id="input-2"
                        v-model="details.distanceFromAirport.name"
                        placeholder="Airport Name"
                        :state="validateState('distanceFromAirport')"
                        aria-describedby="input-airport-live-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-airport-live-feedback">
                        Name is required.
                      </b-form-invalid-feedback>
                    </b-col>
                    <b-col>
                      <b-form-input
                        id="input-2"
                        placeholder="kms"
                        v-model="details.distanceFromAirport.kms"
                        :state="validateState('distanceFromAirport')"
                        aria-describedby="input-kms-live-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-kms-live-feedback">
                        kms is required.
                      </b-form-invalid-feedback>
                    </b-col>
                    <b-col>
                      <b-form-input
                        id="input-2"
                        placeholder="mins"
                        v-model="details.distanceFromAirport.mins"
                        :state="validateState('distanceFromAirport')"
                        aria-describedby="input-time-live-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-time-live-feedback">
                        Time is required.
                      </b-form-invalid-feedback>
                    </b-col>
                  </b-row>
                </b-form-group>
              </div>
              <!-- </b-col> -->
              <!-- 
              <b-row>
                <b-col> -->
              <!-- Distance From Station -->
              <div>
                <b-form-group>
                  <label class="font-18">Distance From Station</label>
                  <b-row>
                    <b-col>
                      <b-form-input
                        id="input-2"
                        placeholder="Station Name"
                        v-model="details.distanceFromStation.name"
                      ></b-form-input>
                    </b-col>
                    <b-col>
                      <b-form-input
                        id="input-2"
                        placeholder="kms"
                        v-model="details.distanceFromStation.kms"
                      ></b-form-input>
                    </b-col>
                    <b-col>
                      <b-form-input
                        id="input-2"
                        placeholder="mins"
                        v-model="details.distanceFromStation.mins"
                        required
                      ></b-form-input>
                    </b-col>
                  </b-row>
                </b-form-group>
              </div>
            </div>
            <!-- Venu info -->
            <div>
              <div class="font-26 py-3">Venues Info</div>
              <b-row>
                <b-col md="4">
                  <!-- No. of Indoor Venues -->
                  <div>
                    <b-form-group>
                      <label class="font-18">No. of Indoor Venues*</label>
                      <b-form-input
                        id="input-2"
                        @keyup="checkVenuesQuantity(details.noOfIndoorVenues)"
                        v-model="details.noOfIndoorVenues"
                        :state="validateState1('noOfIndoorVenues')"
                        aria-describedby="input-noOfIndoorVenues-live-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-noOfIndoorVenues-live-feedback">
                        Number of Indoor Venues is required.
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                </b-col>
                <b-col md="4">
                  <!-- No. of Outdoor Venues -->
                  <div>
                    <b-form-group>
                      <label class="font-18">No. of Outdoor Venues*</label>
                      <b-form-input
                        id="input-2"
                        @keyup="checkVenuesQuantity(details.noOfOutdoorVenues)"
                        v-model="details.noOfOutdoorVenues"
                        :state="validateState1('noOfOutdoorVenues')"
                        aria-describedby="input-noOfOutdoorVenues-live-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-noOfOutdoorVenues-live-feedback">
                        Number of Outdoor Venues is required.
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="4">
                  <!-- No.of Restaurants -->
                  <div>
                    <b-form-group>
                      <label class="font-18">No.of Restaurants*</label>
                      <b-form-input
                        id="input-2"
                        @keyup="checkRestaurantsQuantity(details.NoOfRestaurants)"
                        v-model="details.NoOfRestaurants"
                        :state="validateState2('NoOfRestaurants')"
                        aria-describedby="input-NoOfRestaurants-live-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-NoOfRestaurants-live-feedback">
                        Number of Restaurant is required.
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
            </div>
            <!-- language info -->
            <div>
              <div class="font-26 py-3">Language Info*</div>
              <b-form-group>
                <multiselect
                  class="multiple"
                  v-model="details.languageSpoken"
                  track-by="language"
                  :multiple="true"
                  label="language"
                  name="language"
                  :options="languageData"
                ></multiselect>
              </b-form-group>
            </div>
            <div v-if="showError" class="error-text pt-2 text-center">
              {{ message }}
            </div>
            <div class="d-flex justify-content-center pt-3">
              <div>
                <b-button variant="success" @click="saveData()">Save</b-button>
              </div>
              <div class="ml-3">
                <b-button variant="danger">Cancel</b-button>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import service from "@/service/apiservice";
import global from "@/service/global";
import Multiselect from "vue-multiselect";
import data from "@/js/data";
import country from "@/js/country";
import state from "@/js/state";
import city from "@/js/city";
import Editor from "@tinymce/tinymce-vue";

export default {
  // props: ["detail"],
  components: {
    Multiselect
  },
  mixins: [validationMixin],

  data() {
    return {
      selected1: null,
      selected2: null,
      selected3: null,
      selected4: null,

      zone: [
        { value: "North", text: "North" },
        { value: "East", text: "East" },
        { value: "South", text: "South" },
        { value: "West", text: "West" }
      ],
      world: [
        { value: "Middle East", text: " Middle East" },
        { value: "South East Asia", text: "South East Asia" },
        { value: "India", text: "India" },
        { value: "Europe", text: "Europe" }
        // { value: "others with Auto Type", text: "others with Auto Type" }
      ],
      option: {
        height: 300,
        /* enable automatic uploads of images represented by blob or data URIs*/
        automatic_uploads: true,
        /*
      file_picker_types: "image",
      /* and here's our custom image picker*/
        file_picker_callback: function(cb, value, meta) {
          var input = document.createElement("input");
          input.setAttribute("type", "file");
          input.setAttribute("accept", "image/*");
          input.onchange = function() {
            var file = this.files[0];
            var reader = new FileReader();
            reader.onload = function() {
              var id = "blobid" + new Date().getTime();
              var blobCache = tinymce.activeEditor.editorUpload.blobCache;
              var base64 = reader.result.split(",")[1];
              var blobInfo = blobCache.create(id, file, base64);
              blobCache.add(blobInfo);
              /* call the callback and populate the Title field with the file name */
              cb(blobInfo.blobUri(), { title: file.name });
            };
            reader.readAsDataURL(file);
          };
          input.click();
        }
      },
      details: {
        name: null,
        state: null,
        starRating: "",
        hotelType: null,
        distanceFromAirport: {
          name: "",
          kms: "",
          time: ""
        },
        distanceFromStation: {
          name: "",
          kms: "",
          time: ""
        },
        NoOfRooms: "",
        NoOfGuest: "",
        address: "",
        city: "",
        pinCode: "",
        state: "",
        country: "",
        noOfIndoorVenues: "",
        noOfOutdoorVenues: "",
        NoOfRestaurants: "",
        progressPage: "",
        areaOfProperty: "",
        progressCompleted: "",
        languageSpoken: null,
        hotelDesc: "",
        zoneOfIndia: "",
        world: ""
      },
      showError: false,
      message: "",
      options: [
        { value: "5", text: "5 Star" },
        { value: "4", text: "4 Star" },
        { value: "3", text: "3 Star" }
      ],
      property: [],
      showButton: false,
      allCountry: [],
      allState: state,
      allCity: city,
      state: [],
      city: [],
      languageData: data,
      hotelId: ""
    };
  },
  validations: {
    details: {
      name: {
        required
      },
      hotelType: {
        required
      },
      NoOfRooms: {
        required
      },
      NoOfGuest: {
        required
      },
      address: {
        required
      },
      city: {
        required
      },
      //   pinCode: {
      //     required
      //   },
      state: {
        required
      },
      country: {
        required
      },
      noOfIndoorVenues: {
        required
      },
      noOfOutdoorVenues: {
        required
      },
      NoOfRestaurants: {
        required
      },

      languageSpoken: {
        required
      },
      distanceFromAirport: {
        name: {
          required
        },
        kms: {
          required
        },
        time: {
          required
        }
      },
      starRating: {
        required
      },
      areaOfProperty: {
        required
      }
    }
  },
  created() {
    this.allCountry = country;
  
    this.getOneHotel();
    service.getHotelType(propertyTypeArray => {
      if (propertyTypeArray) {
        if (_.isEmpty(propertyTypeArray.data)) {
          this.property = [];
        } else {
          this.property = propertyTypeArray.data;
        
        }
      }
    });
  },
  methods: {
    getOneHotel() {
      this.hotelId = global.getHotel();
      service.getOneHotel(this.hotelId, data => {
        if (data.code === 200) {
          this.details = data.data;
         
        }
      });
    },
    getState(country) {
      this.state = [];
      if (country) {
        _.each(this.allState, states => {
          if (states.country_id == country.value) {
            this.state.push({ text: states.name, value: states.id });
          }
        });
      }
    },
    getCity(country, state) {
      this.city = [];
      if (state) {
        _.each(this.allCity, cities => {
          if (cities.state_id == state.value) {
            this.city.push({ text: cities.name, value: cities.id });
          }
        });
      }
    },
    // selectedStatus(option) {
  
    //   this.selected2 = option;
    //   this.details.zoneOfIndia = option.text;
    // },
    // selectedWorld(option) {
    //   this.selected3 = option;
    //   this.details.world = option.text;
    // },
    checkVenuesQuantity(venues) {
    
      if (venues > 25) {
        this.$toaster.error("Venues should not be more than 25");
        return;
      }
    },

    checkRestaurantsQuantity(restaurant) {
  

      if (restaurant > 25) {
        this.$toaster.error("No of Restaurants should not be more than 25");
        return;
      }
    },

    validateState(name) {
      const { $dirty, $error } = this.$v.details[name];

      return $dirty ? !$error : null;
    },
    validateState1(name) {
      const { $dirty, $error } = this.$v.details[name];
      if (this.details.noOfIndoorVenues > 25) {
        return $dirty ? $error : null;
      } else if (this.details.noOfOutdoorVenues > 25) {
        return $dirty ? $error : null;
      } else {
        return $dirty ? !$error : null;
      }
    },
    validateState2(name) {
      const { $dirty, $error } = this.$v.details[name];
      if (this.details.NoOfRestaurants > 25) {
        return $dirty ? $error : null;
      } else {
        return $dirty ? !$error : null;
      }
    },
    saveData() {
      this.$v.details.$touch();
      if (this.$v.details.$anyError) {
     
        return;
      } else {
        if (this.details.noOfIndoorVenues > 25) {
          this.showError = true;
          this.message = "No of Indoor Venues cannot be more than 25";
        } else if (this.details.noOfOutdoorVenues > 25) {
          this.showError = true;
          this.message = "No of Outdoor Venues cannot be more than 25";
        } else if (this.details.NoOfRestaurants > 25) {
          this.showError = true;
          this.message = "No of Restaurants cannot be more than 25";
        } else {
          if (this.details.progressCompleted == true) {
            this.details.progressPage = "covid";
          } else {
            this.details.progressPage = "about";
          }
          if (this.details.country.text) {
            this.details.country = this.details.country.text;
          }
          if (this.details.state.text) {
            this.details.state = this.details.state.text;
          }

          if (this.details.city) {
            this.details.city = this.details.city.text;
          }
     
          return new Promise((resolve, reject) => {
            service.updateHotelAdmin(this.details, this.hotelId, result => {
              if (result) {
           
                this.$toaster.success("Details saved.");
                resolve();
              } else {
                reject();
              }
            });
          });
        }
      }
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/scss/variables";
.welcome-content {
  .text {
    color: black;
  }
  .btn-secondary {
    background: transparent;
    border-color: lightgray;
    color: grey;
    box-shadow: none;
    &:hover,
    &:focus,
    &:active {
      background: transparent;
      border-color: transparent;
      color: black;
      box-shadow: none;
    }
  }
  .welcome-first-section {
    .custom-select {
      border-radius: 0px;
    }
    .form-control {
      border-radius: 0px;
    }
  }
}
@import "vue-select/src/scss/vue-select.scss";
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
