<template>
  <div>
    <div class="faq-section">
      <div class="faq-title font-32">
        <div class="text-center">FAQ's</div>
      </div>
      <div>
        {{ hotelFaq }}
        <b-form>
          <div v-for="item in hotelFaq" v-bind:key="item._id">
            <!-- music -->
            <div>
              <div class="font-26 py-3">{{ item._id }}</div>
              <div v-for="que in item.question" v-bind:key="que._id">
                <b-form-group>
                  <label class="font-18 form-label"> {{ que.question }}</label>
                  <b-form-input
                    id="input-1"
                    type="text"
                    v-model="que.answer"
                    required
                    readonly
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>
<script>
import service from "@/service/apiservice";

export default {
  data() {
    return {
      hotelFaq: {}
    };
  },
  created() {
    this.getOneHotelFaq();
  },
  methods: {
    getOneHotelFaq() {
      const obj = {
        id: this.$route.params.id
      };

      service.getOneHotelFaq(obj, data => {
        if (data.code === 200) {
          this.hotelFaq = data.data;
        }
      });
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/scss/variables";

.faq-section {
  .form-label {
    font-family: $roboto-regular;
  }
}
</style>
