<template>
  <div class="facilities-section futura-std-book pb-5">
    <div class="text-center">
      <div class="font-36">Facilities</div>
    </div>
    <div class="facilites-form-section">
      <!-- outdoor section -->
      <div class="outdoor-section">
        <div class="outdoor-title font-18 pt-4">
          Outdoor
        </div>
        {{ message }}
        <div class="outdoor-form-section pt-3">
          <b-row>
            <b-col id="first-section" md="6">
              <div v-for="outdoor in outdoors" :key="outdoor.key">
                <b-row class="pt-2">
                  <b-col md="6" class="pt-2">
                    <b-form-checkbox-group id="outdoor" v-model="tempOutdoor">
                      <b-form-checkbox
                        class="font-14 gray2"
                        :value="{
                          name: outdoor.name,
                          outdoor: outdoor._id
                        }"
                      >
                        {{ outdoor.name }}
                      </b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-col>

                  <b-col md="6" class="pt-2">
                    <div>
                      <b-form-select
                        v-model="outdoor.selected"
                        :options="outDoorOptions"
                      ></b-form-select>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <!-- activites section -->
      <div class="activities-section">
        <div class="activities-title font-18 pt-4">
          Activities
        </div>
        <div class="activities-form-section pt-3">
          <b-row>
            <b-col v-for="activity in activities" :key="activity.key" md="6">
              <div>
                <b-row class="pt-2">
                  <b-col md="6" class="pt-2">
                    <b-form-checkbox-group id="activity" v-model="tempActivities">
                      <b-form-checkbox
                        class="font-14 gray2"
                        :value="{
                          name: activity.name,
                          activitie: activity._id
                        }"
                      >
                        {{ activity.name }}
                      </b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-col>

                  <b-col md="6" class="pt-2">
                    <div>
                      <b-form-select v-model="activity.selected" :options="options"></b-form-select>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <!-- wifi seciton -->
      <div class="wifi-section">
        <div class="wifi-title font-18 pt-4">
          Wifi
        </div>
        <div class="wifi-form-section pt-3">
          <b-row>
            <b-col v-for="wifi in wifi" :key="wifi.key" md="6">
              <div>
                <b-row>
                  <b-col md="6" class="pt-2">
                    <b-form-checkbox-group id="wifi" v-model="tempwifi">
                      <b-form-checkbox
                        class="font-14 gray2"
                        :value="{
                          name: wifi.name,
                          wifi: wifi._id
                        }"
                      >
                        {{ wifi.name }}
                      </b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-col>

                  <b-col md="6" class="pt-2">
                    <div>
                      <b-form-select v-model="wifi.selected" :options="options"></b-form-select>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <!-- cleaning service section -->
      <div class="cleaning-service-section">
        <div class="cleaning-title font-18 pt-5">
          Cleaning Services
          <span class="font-12"></span>
        </div>
        <div class="cleaning-form-section pt-3">
          <b-row>
            <b-col v-for="services in cleaningServices" :key="services.key" md="6">
              <div>
                <b-row class="pt-2">
                  <b-col md="6" class="pt-2">
                    <b-form-checkbox-group
                      id="service"
                      v-model="tempcleaningService"
                      name="flavour-2"
                    >
                      <b-form-checkbox
                        class="font-14 gray2"
                        :value="{
                          name: services.name,
                          cleaningService: services._id
                        }"
                      >
                        {{ services.name }}
                      </b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-col>

                  <b-col md="6" class="pt-2">
                    <div>
                      <b-form-select v-model="services.selected" :options="options"></b-form-select>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <!-- transportation section -->
      <div class="transportation-section">
        <div class="transport-title font-18 pt-4">
          Transportation
        </div>
        <div class="transport-form-section pt-3">
          <b-row>
            <b-col v-for="transport in transportation" :key="transport.key" md="6">
              <div>
                <b-row>
                  <b-col md="6" class="pt-2">
                    <b-form-checkbox-group
                      id="transport"
                      v-model="tempTransportation"
                      name="flavour-2"
                    >
                      <b-form-checkbox
                        class="font-14 gray2"
                        :value="{
                          name: transport.name,
                          transportation: transport._id
                        }"
                      >
                        {{ transport.name }}
                      </b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-col>

                  <b-col md="6" class="pt-2">
                    <div>
                      <b-form-select
                        v-model="transport.selected"
                        :options="options"
                      ></b-form-select>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <!-- front desk service section -->
      <div class="front-desk-section">
        <div class="front-desk-title font-18 pt-4">
          Front Desk Services
          <span class="font-12">(Select minimum 2)</span>
        </div>
        <div class="front-desk-form-section pt-3">
          <b-row>
            <b-col v-for="frontDesk in frontDeskServices" :key="frontDesk.key" md="6">
              <div>
                <b-row class="pt-2">
                  <b-col md="6" class="pt-2">
                    <b-form-checkbox-group id="frontDesk" v-model="tempFrontDeskServices">
                      <b-form-checkbox
                        class="font-14 gray2"
                        :value="{
                          name: frontDesk.name,
                          frontDeskService: frontDesk._id
                        }"
                      >
                        {{ frontDesk.name }}
                      </b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <!-- safety and security section -->
      <div class="safety-security-section">
        <div class="safety-title font-18 pt-4">
          Safety &amp; Security
          <span class="font-12">(Select minimum 2)</span>
        </div>
        <div class="safety-form-section pt-3">
          <b-row>
            <b-col v-for="safety in safetyNSecurity" :key="safety.key" md="6">
              <div>
                <b-row class="pt-2">
                  <b-col md="6" class="pt-2">
                    <b-form-checkbox-group
                      id="security"
                      v-model="tempSafetyNSecurity"
                      name="flavour-2"
                    >
                      <b-form-checkbox
                        class="font-14 gray2"
                        :value="{
                          name: safety.name,
                          safetyNSecurityId: safety._id
                        }"
                      >
                        {{ safety.name }}
                      </b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <!-- health and wellness facilities section -->
      <div class="health-section">
        <div class="health-title font-18 pt-4">
          Health &amp; Wellness Facilities
        </div>
        <div class="health-form-section pt-3">
          <b-row>
            <b-col v-for="health in healthNWellnessFacilities" :key="health.key" md="6">
              <div>
                <b-row class="pt-2">
                  <b-col md="6" class="pt-2">
                    <b-form-checkbox-group
                      id="checkbox-group-2"
                      v-model="tempHealthNWellnessFacilities"
                    >
                      <b-form-checkbox
                        class="font-14 gray2"
                        :value="{
                          name: health.name,
                          HealthNWellnessFacility: health._id
                        }"
                      >
                        {{ health.name }}
                      </b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-col>

                  <b-col md="6" class="pt-2">
                    <div>
                      <b-form-select v-model="health.selected" :options="options"></b-form-select>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <!-- general section -->
      <div class="general-section">
        <div class="general-title font-18 pt-4">
          General
          <span class="font-12">(Select minimum 2)</span>
        </div>
        <div class="general-form-section pt-3">
          <b-row>
            <b-col v-for="item in general" :key="item.key" md="6">
              <div>
                <b-row class="pt-2">
                  <b-col md="6" class="pt-2">
                    <b-form-checkbox-group id="general" v-model="tempGeneral">
                      <b-form-checkbox
                        class="font-14 gray2"
                        :value="{
                          name: item.name,
                          general: item._id
                        }"
                      >
                        {{ item.name }}
                      </b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-col>

                  <b-col md="6" class="pt-2">
                    <div>
                      <b-form-select v-model="item.selected" :options="options"></b-form-select>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <div v-if="showError" class="error-text pt-2 text-center">
      {{ message }}
    </div>
    <div class="d-flex justify-content-center">
      <div>
        <b-button variant="success" @click="saveFacility()">Save</b-button>
      </div>
      <div class="ml-3">
        <b-button variant="danger">Cancel</b-button>
      </div>
    </div>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import service from "@/service/apiservice";
import global from "@/service/global";
export default {
  // props: ["detail"],
  data() {
    return {
      detail: {
        facilities: {
          outdoors: [],
          activities: [],
          wifi: [],
          cleaningServices: [],
          transportation: [],
          healthNWellnessFacilities: [],
          frontDeskServices: [],
          safetyNSecurity: [],
          general: []
        },
        progressCompleted: "",
        progressPage: ""
      },
      outdoors: [],
      activities: [],
      wifi: [],
      cleaningServices: [],
      transportation: [],
      frontDeskServices: [],
      safetyNSecurity: [],
      healthNWellnessFacilities: [],
      general: [],
      selected: null,
      options: [
        { value: "Chargeable", text: "Chargeable" },
        { value: "Complimentary", text: "Complimentary" }
      ],
      outDoorOptions: [
        { value: "Event can be hosted", text: "Event can be hosted" },
        {
          value: "Event can be hosted in an assigned area",
          text: "Event can be hosted in an assigned area"
        },
        { value: "Guest use only", text: "Guest use only" }
      ],
      tempOutdoor: [],
      tempActivities: [],
      tempwifi: [],
      tempcleaningService: [],
      tempTransportation: [],
      tempFrontDeskServices: [],
      tempSafetyNSecurity: [],
      tempHealthNWellnessFacilities: [],
      tempGeneral: [],
      showError: false,
      message: "",
      hotelId: ""
    };
  },
  created() {
    this.getOneHotel();
  },
  methods: {
    getOneHotel() {
      this.hotelId = global.getHotel();
      service.getOneHotel(this.hotelId, data => {
        if (data.code === 200) {
          this.detail = data.data;
          // for general
          service.getGeneral(Result => {
            this.general = Result.data;
            this.tempGeneral = this.detail.facilities.general;
            _.each(this.tempGeneral, myGeneral => {
              const index = _.findIndex(this.general, ["_id", myGeneral.general]);
              this.general[index].selected = myGeneral.facilityType;
              delete myGeneral.facilityType;
              delete myGeneral._id;
            });
          });
          // for healthNWellnessFacilities
          service.getHealthNWellnessFacilities(Result => {
            this.healthNWellnessFacilities = Result.data;
            this.tempHealthNWellnessFacilities = this.detail.facilities.healthNWellnessFacilities;
            _.each(this.tempHealthNWellnessFacilities, myHealth => {
              const index = _.findIndex(this.healthNWellnessFacilities, [
                "_id",
                myHealth.HealthNWellnessFacility
              ]);
              this.healthNWellnessFacilities[index].selected = myHealth.facilityType;
              delete myHealth.facilityType;
              delete myHealth._id;
            });
          });
          // for safetyNSecurity
          service.getSafetyNSecurity(Result => {
            this.safetyNSecurity = Result.data;
            this.tempSafetyNSecurity = this.detail.facilities.safetyNSecurity;
            _.each(this.tempSafetyNSecurity, mySafety => {
              const index = _.findIndex(this.safetyNSecurity, ["_id", mySafety.safetyNSecurityId]);

              delete mySafety._id;
            });
          });

          // for FrontDeskServices
          service.getFrontDeskServices(frontDeskServicesResult => {
            this.frontDeskServices = frontDeskServicesResult.data;
            this.tempFrontDeskServices = this.detail.facilities.frontDeskServices;
            _.each(this.tempFrontDeskServices, myServ => {
              const index = _.findIndex(this.frontDeskServices, ["_id", myServ.frontDeskService]);

              delete myServ._id;
            });
          });
          // for Transportation
          service.getTransportation(transportationResult => {
            this.transportation = transportationResult.data;
            this.tempTransportation = this.detail.facilities.transportation;
            _.each(this.tempTransportation, mytrans => {
              const index = _.findIndex(this.transportation, ["_id", mytrans.transportation]);
              this.transportation[index].selected = mytrans.facilityType;
              delete mytrans.facilityType;
              delete mytrans._id;
            });
          });

          // for cleaningServices
          service.getCleaningServices(cleaningResult => {
            this.cleaningServices = cleaningResult.data;
            this.tempcleaningService = this.detail.facilities.cleaningServices;
            _.each(this.tempcleaningService, myservice => {
              const index = _.findIndex(this.cleaningServices, ["_id", myservice.cleaningService]);
              this.cleaningServices[index].selected = myservice.facilityType;
              delete myservice.facilityType;
              delete myservice._id;
            });
          });
          // for wifi
          service.getWifi(wifiResult => {
            this.wifi = wifiResult.data;
            this.tempwifi = this.detail.facilities.wifi;
            _.each(this.tempwifi, mywifi => {
              const index = _.findIndex(this.wifi, ["_id", mywifi.wifi]);
              this.wifi[index].selected = mywifi.facilityType;
              delete mywifi.facilityType;
              delete mywifi._id;
            });
          });

          // for outdoor
          service.getOutdoors(outdoorsResult => {
            this.outdoors = outdoorsResult.data;
            this.tempOutdoor = this.detail.facilities.outdoors;
            _.each(this.tempOutdoor, myOutdoor => {
              const index = _.findIndex(this.outdoors, ["_id", myOutdoor.outdoor]);
              this.outdoors[index].selected = myOutdoor.facilityType;
              delete myOutdoor.facilityType;
              delete myOutdoor._id;
            });
          });
          // for activities
          service.getActivities(activitiesResult => {
            this.activities = activitiesResult.data;
            this.tempActivities = this.detail.facilities.activities;
            _.each(this.tempActivities, myActivities => {
              const index = _.findIndex(this.activities, ["_id", myActivities.activitie]);
              this.activities[index].selected = myActivities.facilityType;
              delete myActivities.facilityType;
              delete myActivities._id;
            });
          });
        }
      });
    },
    saveFacility() {
      // for general
      this.detail.facilities.general = [];
      _.each(this.general, data => {
        if (data.selected && data.selected != null) {
          this.detail.facilities.general.push({
            general: data._id,
            facilityType: data.selected,
            name: data.name
          });
        }
      });

      // for healthNWellnessFacilities
      this.detail.facilities.healthNWellnessFacilities = [];
      _.each(this.healthNWellnessFacilities, data => {
        if (data.selected && data.selected != null) {
          this.detail.facilities.healthNWellnessFacilities.push({
            HealthNWellnessFacility: data._id,
            facilityType: data.selected,
            name: data.name
          });
        }
      });
      // for safetyNSecurity
      this.detail.facilities.safetyNSecurity = [];
      _.each(this.safetyNSecurity, data => {
        _.each(this.tempSafetyNSecurity, temp => {
          if (data._id == temp.safetyNSecurityId) {
            this.detail.facilities.safetyNSecurity.push({
              safetyNSecurityId: data._id,
              name: data.name
            });
          }
        });
      });
      // for  businessFacilities
      this.detail.facilities.businessFacilities = [];
      _.each(this.businessFacilities, data => {
        if (data.selected && data.selected != null) {
          this.detail.facilities.businessFacilities.push({
            businessFacility: data._id,
            facilityType: data.selected,
            name: data.name
          });
        }
      });
      // for entertainmentNFamilyServices
      this.detail.facilities.entertainmentNFamilyServices = [];
      _.each(this.entertainmentNFamilyServices, data => {
        if (data.selected && data.selected != null) {
          this.detail.facilities.entertainmentNFamilyServices.push({
            entertainmentNFamilyService: data._id,
            facilityType: data.selected,
            name: data.name
          });
        }
      });
      // for frontDeskServices
      this.detail.facilities.frontDeskServices = [];
      _.each(this.frontDeskServices, data => {
        _.each(this.tempFrontDeskServices, temp => {
          if (data._id == temp.frontDeskService) {
            this.detail.facilities.frontDeskServices.push({
              frontDeskService: data._id,
              name: data.name
            });
          }
        });
      });

      // for Transportation
      this.detail.facilities.transportation = [];
      _.each(this.transportation, data => {
        if (data.selected && data.selected != null) {
          this.detail.facilities.transportation.push({
            transportation: data._id,
            facilityType: data.selected,
            name: data.name
          });
        }
      });
      // for parking
      this.detail.facilities.parking = [];
      _.each(this.parking, data => {
        if (data.selected && data.selected != null) {
          this.detail.facilities.parking.push({
            parkingId: data._id,
            facilityType: data.selected,
            name: data.name
          });
        }
      });
      // For cleaningService
      this.detail.facilities.cleaningServices = [];
      _.each(this.cleaningServices, data => {
        if (data.selected && data.selected != null) {
          this.detail.facilities.cleaningServices.push({
            cleaningService: data._id,
            facilityType: data.selected,
            name: data.name
          });
        }
      });
      // For wifi
      this.detail.facilities.wifi = [];
      _.each(this.wifi, data => {
        if (data.selected && data.selected != null) {
          this.detail.facilities.wifi.push({
            wifi: data._id,
            facilityType: data.selected,
            name: data.name
          });
        }
      });

      // For FNB
      this.detail.facilities.fNb = [];
      _.each(this.fNb, data => {
        if (data.selected && data.selected != null) {
          this.detail.facilities.fNb.push({
            fNb: data._id,
            facilityType: data.selected,
            name: data.name
          });
        }
      });

      // For OUTDOOR
      this.detail.facilities.outdoors = [];
      _.each(this.outdoors, data => {
        if (data.selected && data.selected != null) {
          this.detail.facilities.outdoors.push({
            outdoor: data._id,
            facilityType: data.selected,
            name: data.name
          });
        }
      });
      // For Activities
      this.detail.facilities.activities = [];
      _.each(this.activities, data => {
        if (data.selected && data.selected != null) {
          this.detail.facilities.activities.push({
            activitie: data._id,
            facilityType: data.selected,
            name: data.name
          });
        }
      });
      if (this.detail) {
        if (this.detail.facilities.frontDeskServices.length < 2) {
          this.$toaster.error("Select atleast 2 frontDeskServices");
        } else if (this.detail.facilities.safetyNSecurity.length < 2) {
          this.$toaster.error("Select atleast 2 safetyNSecurity");
        } else if (this.detail.facilities.general.length < 2) {
          this.$toaster.error("Select atleast 2 General");
        } else {
          if (this.detail.progressCompleted == true) {
            this.detail.progressPage = "covid";
          } else {
            this.detail.progressPage = "facilities";
          }

          return new Promise((resolve, reject) => {
            service.updateHotelAdmin(this.detail, this.hotelId, saveResult => {
              if (saveResult) {
                this.$toaster.success("Details saved.");
                this.showError = false;
                resolve();
              } else {
                reject();
              }
            });
          });
        }
      }
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/scss/variables";

.facilities-section {
  .btn-outline-primary {
    color: $gray;
    border-color: $gray;
    padding: 0.375rem 2.55rem;
    &:hover {
      background-color: $white;
    }
  }
  .btn-primary {
    color: $gray;
    background-color: $white;
    border-color: $gray;
  }

  .btn {
    border-radius: 0px;
  }

  .custom-checkbox .custom-control-label::before {
    border-radius: 0px;
  }
  .custom-control {
    margin: 5px 0px;
  }
  .checkbox-control {
    border-radius: 0px;
  }
}
</style>
