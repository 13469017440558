<template>
  <div class="restaurant-details">
    <div class="restaurant-details-section">
      <div>
        <div class="text-center">
          <div class="font-36">Restaurant</div>
        </div>
        <div>
          <b-form>
            <b-row>
              <b-col id="first-section" md="12">
                <b-row v-for="(content, index) in detail.restaurants" :key="content.key">
                  <b-col md="12" class="border-pink" v-if="index != 0">
                    <div class="icon-section text-right py-2">
                      <font-awesome-icon
                        :icon="['fas', 'times']"
                        class="red font-16 pointer"
                        @click="removeData(index)"
                      />
                    </div>
                  </b-col>
                  <b-col md="6">
                    <!-- Name of Restaurant -->
                    <div>
                      <b-form-group>
                        <label class="font-14">
                          Name of Restaurant*
                        </label>
                        <b-form-input
                          :state="validateState('name', index)"
                          aria-describedby="input-name-live-feedback"
                          id="res"
                          v-model="content.name"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-name-live-feedback">
                          Restaurant Name is required.
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                    <!-- Guest Capacity -->
                    <div>
                      <b-form-group>
                        <label class="font-14">
                          Guest Capacity*
                        </label>
                        <b-form-input
                          type="number"
                          :state="validateState('capacity', index)"
                          aria-describedby="input-capacity-live-feedback"
                          id="capacity"
                          v-model="content.capacity"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-capacity-live-feedback">
                          Capacity is required.
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                    <div>
                      <b-form-group>
                        <label class="font-14">Remarks</label>
                        <b-form-input id="capacity" v-model="content.remark"></b-form-input>
                      </b-form-group>
                    </div>
                  </b-col>
                  <b-col md="6">
                    <!-- Cuisine Type -->
                    <div>
                      <b-form-group>
                        <label class="font-14">
                          Cuisine*
                        </label>
                        <b-form-input
                          :state="validateState('restaurantsType', index)"
                          aria-describedby="input-restaurantsType-live-feedback"
                          id="capacity"
                          v-model="content.restaurantsType"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-restaurantsType-live-feedback">
                          Cuisine Type is required.
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </div>

                    <div>
                      <b-form-group>
                        <label class="font-14">
                          Booking for Private Event*
                        </label>
                        <b-form-select
                          :state="validateState('bookingForPrivateEvent', index)"
                          aria-describedby="input-bookingForPrivateEvent-live-feedback"
                          :options="options"
                          v-model="content.bookingForPrivateEvent"
                        ></b-form-select>
                        <b-form-invalid-feedback id="input-bookingForPrivateEvent-live-feedback">
                          Booking For Private Event is required.
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <!-- Add Restaurant btn -->
            <div v-if="detail.restaurants.length < 25">
              <b-button class="add-btn font-14 mt-4" @click="add()">Add Restaurant</b-button>
            </div>
            <div v-if="showError" class="error-text pt-2 text-center">
              {{ message }}
            </div>
            <div class="d-flex justify-content-center pt-5">
              <div>
                <b-button variant="success" @click="updateHotel()">Save</b-button>
              </div>
              <div class="ml-3">
                <b-button variant="danger">Cancel</b-button>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import service from "@/service/apiservice";
import global from "@/service/global";

export default {
  // props: ["detail"],

  data() {
    return {
      detail: {
        restaurants: [],
        progressPage: "",
        progressCompleted: ""
      },
      restaurants: {
        name: "",
        restaurantsType: "",
        capacity: "",
        bookingForPrivateEvent: "",
        remark: ""
      },

      options: [
        {
          value: "Exclusive Booking for Private Event",
          text: "Exclusive Booking for Private Event"
        },
        {
          value: "Only Section Booking for Private Event",
          text: "Only Section Booking for Private Event"
        },
        {
          value: "Not Available for Private Event",
          text: "Not Available for Private Event"
        }
      ],

      showError: false,
      message: "",
      hotelId: ""
    };
  },
  validations: {
    detail: {
      restaurants: {
        required,
        $each: {
          name: {
            required
          },
          capacity: {
            required
          },
          restaurantsType: {
            required
          },
          bookingForPrivateEvent: {
            required
          }
          // file: {
          //     required,
          // },
        }
      }
    }
  },
  created() {
    this.detail.restaurants.push(this.restaurants);
    this.getOneHotel();
  },
  methods: {
    getOneHotel() {
      this.hotelId = global.getHotel();
      service.getOneHotel(this.hotelId, data => {
        if (data.code === 200) {
          this.detail = data.data;
        }
      });
    },
    validateState(name, index) {
      const { $dirty, $error } = this.$v.detail.restaurants.$each[index][name];
      return $dirty ? !$error : null;
    },
    removeData(index) {
      this.detail.restaurants.splice(index, 1);
    },
    add() {
      this.detail.restaurants.push({
        name: "",
        restaurantsType: "",
        capacity: "",
        timing: "",
        bookingForPrivateEvent: "",
        remark: ""
      });
    },
    updateHotel() {
      this.$v.detail.restaurants.$touch();
      if (this.detail.restaurants.length > 0) {
        if (this.$v.detail.restaurants.$anyError) {
          this.$toaster.error("Please fill all mandatory fields.");

          return;
        }
        if (this.detail.restaurants.length <= 1 && !this.detail.restaurants[0].name) {
          this.showError = true;
          this.message = "Add atleast 1 Restaurant";
        } else {
          var save = {
            restaurants: []
          };
          _.map(this.detail.restaurants, function(e) {
            if (e.name) {
              save.restaurants.push(e);
            } else {
              delete e.restaurants;
            }
          });
          if (save.restaurants) {
            if (this.detail.progressCompleted == true) {
              save.progressPage = "covid";
            } else {
              save.progressPage = "resturants";
            }

            return new Promise((resolve, reject) => {
              service.updateHotelAdmin(save, this.hotelId, saveResult => {
                if (saveResult) {
                  this.$toaster.success("Details saved.");
                  this.showError = false;
                  this.showButton = true;
                  resolve();
                } else {
                  reject();
                }
              });
            });
          }
        }
      } else {
        this.showError = true;
        this.message = "Add atleast 1 Restaurant";
      }
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/scss/variables";
.restaurant-details {
  .restaurant-details-section {
    .custom-select {
      border-radius: 0px;
    }
    .form-control {
      border-radius: 0px;
    }
    .card {
      border: none;
    }
    #second-section {
      margin-left: 3rem;
    }
    .add-btn {
      .btn-link:hover {
        color: $pink;
        text-decoration: none;
      }
    }
  }
}
</style>
