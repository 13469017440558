<template>
  <div class="image-details">
    <div class="image-details-section">
      <div>
        <div class="text-center">
          <div class="font-36">Images &amp; Videos</div>
        </div>
        <!-- image content -->
        <div class="image-section">
          <b-row>
            <b-col id="image-content" md="9">
              <div>
                <div class="font-18 pt-2">
                  Images
                </div>
                <div class="image-selection mt-3">
                  <div class="font-16 col-md-5">
                    Hotel Logo* <br />
                    <span class="text-danger font-10">(JPEG Images Only)</span>
                  </div>
                  <!-- for deskstop -->
                  <div class="font-16 ml-5 d-none d-sm-none d-md-block">
                    <b-button border-0 class="px-5 upload-btn" @click="$refs.fileInput4.click()"
                      >Upload</b-button
                    >
                    <input
                      ref="fileInput4"
                      type="file"
                      multiple
                      style="display:none;"
                      @change="onlogoChange($event.target.name, $event.target.files)"
                    />
                  </div>
                  <div class="font-16 pink ml-2">
                    (Max 200kb)
                  </div>
                  <!-- for mobile -->
                  <div class="font-16 ml-5 d-md-none d-sm-block">
                    <b-button border-0 class @click="$refs.fileInput4.click()">
                      <font-awesome-icon :icon="['fas', 'file-upload']" />
                    </b-button>
                    <input
                      ref="fileInput4"
                      type="file"
                      multiple
                      style="display:none;"
                      @change="onlogoChange($event.target.name, $event.target.files)"
                    />
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <div>
            <b-row gutters>
              <b-col md="2" cols="4" v-for="image in logoImg" class="my-2" :key="image">
                <div>
                  <div class="icon-section">
                    <font-awesome-icon
                      :icon="['fas', 'times']"
                      class="red font-12"
                      @click="removeLogo(image)"
                    />
                  </div>
                  <img :src="image" alt="your image" class="img-fluid images ml-3" />
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
        <!-- hotel logo -->
        <div class="image-section">
          <b-row>
            <b-col id="image-content" md="9">
              <div>
                <div class="image-selection mt-3">
                  <div class="font-16 col-md-5">
                    Cover Image* <br />
                    <span class="text-danger font-10">(JPEG Images Only)</span>
                  </div>
                  <!-- for deskstop -->
                  <div class="font-16 ml-5 d-none d-sm-none d-md-block">
                    <b-button border-0 class="px-5 upload-btn" @click="$refs.fileInput3.click()"
                      >Upload</b-button
                    >
                    <input
                      ref="fileInput3"
                      type="file"
                      accept="image/jpeg"
                      multiple
                      style="display:none;"
                      @change="onCoverChange($event.target.name, $event.target.files)"
                    />
                  </div>
                  <div class="font-16 pink ml-2">
                    (Max 1mb)
                  </div>
                  <!-- for mobile -->
                  <div class="font-16 ml-5 d-md-none d-sm-block">
                    <b-button border-0 class @click="$refs.fileInput3.click()">
                      <font-awesome-icon :icon="['fas', 'file-upload']" />
                    </b-button>
                    <input
                      ref="fileInput3"
                      type="file"
                      multiple
                      style="display:none;"
                      @change="onCoverChange($event.target.name, $event.target.files)"
                    />
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <div>
            <b-row gutters>
              <b-col md="2" cols="4" v-for="image in coverImg" class="my-2" :key="image">
                <div>
                  <div class="icon-section">
                    <font-awesome-icon
                      :icon="['fas', 'times']"
                      class="red font-12"
                      @click="removeCover(image)"
                    />
                  </div>
                  <img :src="image" alt="your image" class="img-fluid images ml-3" />
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
        <!-- hotel Banner -->
        <div class="image-section">
          <b-row>
            <b-col id="image-content" md="9">
              <div>
                <div class="image-selection mt-3">
                  <div class="font-16 col-md-5">
                    Banner Image* <br />
                    <span class="text-danger font-10">(JPEG Images Only)</span>
                  </div>
                  <!-- for deskstop -->
                  <div class="font-16 ml-5 d-none d-sm-none d-md-block">
                    <b-button border-0 class="px-5 upload-btn" @click="$refs.fileInput5.click()"
                      >Upload</b-button
                    >
                    <input
                      ref="fileInput5"
                      type="file"
                      multiple
                      style="display:none;"
                      @change="onFileChange($event.target.name, $event.target.files)"
                    />
                  </div>
                  <div class="font-16 pink ml-2">
                    (Max 1mb)
                  </div>
                  <!-- for mobile -->
                  <div class="font-16 ml-5 d-md-none d-sm-block">
                    <b-button border-0 class @click="$refs.fileInput5.click()">
                      <font-awesome-icon :icon="['fas', 'file-upload']" />
                    </b-button>
                    <input
                      ref="fileInput5"
                      type="file"
                      multiple
                      style="display:none;"
                      @change="onBannerChange($event.target.name, $event.target.files)"
                    />
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <div>
            <b-row gutters>
              <b-col md="2" cols="4" v-for="image in bannerImg" class="my-2" :key="image">
                <div>
                  <div class="icon-section">
                    <font-awesome-icon
                      :icon="['fas', 'times']"
                      class="red font-12"
                      @click="removeBanner(image)"
                    />
                  </div>
                  <img :src="image" alt="your image" class="img-fluid images ml-3" />
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
        <!-- Select images -->
        <div class="image-section">
          <b-row>
            <b-col id="image-content" md="9">
              <div>
                <div class="image-selection mt-3">
                  <div class="font-16 col-md-5">
                    Other Images*
                    <br />
                    <span class="text-danger font-10">(Minimum 5 Images Required)</span>
                  </div>
                  <!-- for deskstop -->
                  <div class="font-16 ml-5 d-none d-sm-none d-md-block">
                    <b-button border-0 class="px-5 upload-btn" @click="$refs.fileInput1.click()"
                      >Upload</b-button
                    >
                    <input
                      ref="fileInput1"
                      type="file"
                      multiple
                      style="display:none;"
                      @change="onFileChange($event.target.name, $event.target.files)"
                    />
                  </div>
                  <div class="font-16 pink ml-2">
                    (Max 1mb)
                  </div>
                  <!-- for mobile -->
                  <div class="font-16 ml-5 d-md-none d-sm-block">
                    <b-button border-0 class @click="$refs.fileInput1.click()">
                      <font-awesome-icon :icon="['fas', 'file-upload']" />
                    </b-button>
                    <input
                      ref="fileInput1"
                      type="file"
                      multiple
                      style="display:none;"
                      @change="onFileChange($event.target.name, $event.target.files)"
                    />
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <div>
            <b-row gutters>
              <b-col md="2" cols="4" v-for="(image, key) in images" class="my-2" :key="image">
                <div>
                  <div>
                    <div class="icon-section">
                      <font-awesome-icon
                        :icon="['fas', 'times']"
                        class="red font-12"
                        @click="remove(key)"
                      />
                    </div>
                    <img :src="image" alt="your image" class="img-fluid set-img images ml-3" />
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>

        <div class="image-section">
          <b-row>
            <b-col id="image-content" md="9">
              <div>
                <div class="image-selection mt-3">
                  <div class="font-16 col-md-5">
                    Real Wedding Image
                    <br />
                    <span class="text-danger font-10">(JPEG Images Only)</span>
                  </div>
                  <!-- for deskstop -->
                  <div class="font-16 ml-5 d-none d-sm-none d-md-block">
                    <b-button border-0 class="px-5 upload-btn" @click="$refs.fileInputWedd.click()"
                      >Upload</b-button
                    >
                    <input
                      ref="fileInputWedd"
                      type="file"
                      multiple
                      style="display:none;"
                      @change="onFileChangeWedding($event.target.name, $event.target.files)"
                    />
                  </div>
                  <div class="font-16 pink ml-2">
                    (Max 1mb)
                  </div>
                  <!-- for mobile -->
                  <div class="font-16 ml-5 d-md-none d-sm-block">
                    <b-button border-0 class @click="$refs.fileInputWedd.click()">
                      <font-awesome-icon :icon="['fas', 'file-upload']" />
                    </b-button>
                    <input
                      ref="fileInputWedd"
                      type="file"
                      multiple
                      style="display:none;"
                      @change="onFileChangeWedding($event.target.name, $event.target.files)"
                    />
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <div>
            <b-row gutters>
              <b-col md="2" cols="4" v-for="(image, key) in wedImages" class="my-2" :key="image">
                <div>
                  <div>
                    <div class="icon-section">
                      <font-awesome-icon
                        :icon="['fas', 'times']"
                        class="red font-12"
                        @click="removeWeddingImages(key)"
                      />
                    </div>
                    <img :src="image" alt="your image" class="img-fluid set-img images ml-3" />
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>

        <!-- video code -->
        <div class="video-section">
          <b-row>
            <b-col md="9">
              <div>
                <div class="font-18 mt-4">
                  Videos
                </div>
                <div class="video-selection my-3">
                  <div class="font-16 col-md-5">
                    Hotel / Wedding Video
                  </div>
                  <!-- for deskstop -->
                  <div class="font-16 ml-5 d-none d-sm-none d-md-block">
                    <b-button
                      :disabled="disableVideoUpload"
                      border-0
                      class="px-5 upload-btn"
                      @click="$refs.fileInput2.click()"
                      >Upload</b-button
                    >
                    <input
                      ref="fileInput2"
                      type="file"
                      multiple
                      style="display:none;"
                      @change="onVideoChange($event.target.name, $event.target.files)"
                    />
                  </div>
                  <div class="video-uploaded font-16 pink ml-1">
                    (Max 100 mb)
                  </div>
                  <div></div>
                  <!-- for mobile -->
                  <div class="d-md-none d-sm-block">
                    <b-button border-0 class>
                      <font-awesome-icon :icon="['fas', 'file-upload']" />
                    </b-button>
                    <input
                      type="file"
                      multiple
                      style="display:none;"
                      @change="onVideoChange($event.target.name, $event.target.files)"
                    />
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row gutters>
            <b-col md="2" cols="4" v-for="(video, key) in hotelVideos" :key="video">
              <div>
                <div @click="removeVideo(key)" class="icon-section z-index-screen">
                  <font-awesome-icon :icon="['fas', 'times']" class="red font-12" />
                </div>
                <video
                  :src="video"
                  alt="your video"
                  type="video/webm"
                  width="100%"
                  class="videos ml-3"
                />
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="d-flex justify-content-center">
          <div>
            <b-button variant="success" @click="saveData()">Save</b-button>
          </div>
          <div class="ml-3">
            <b-button variant="danger">Cancel</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import service from "@/service/apiservice";
import global from "@/service/global";
export default {
  data() {
    return {
      errors: [],
      packgid: "",
      imagecount: 0,
      videocount: 0,
      showButton: false,
      packageInfo: {
        packages: {
          features: [{ name: "", value: "", content: "" }]
        }
      },
      detail: {
        photos: [],
        videos: [],
        coverImage: [],
        bannerImage: [],
        logoImage: [],
        weddingImage: []
      },
      url: null,
      images: [],
      hotelVideos: [],
      imageUrl: "",
      videoUrl: "",
      coverFile: "",
      imageFile: "",
      videoFile: "",
      bannerFile: "",
      logoFile: "",
      file: "",
      PhotoName: "",
      videoName: "",
      s3URL: "",
      wedImagesURL: "",
      wedImages: [],
      hotelInfo: [],
      contentVI: "",
      coverImg: [],
      logoImg: [],
      bannerImg: [],
      progressPage: "",
      progressCompleted: "",
      disableVideoUpload: false,
      PhotoNameWed: "",
      imageUrlWed: "",
      imageFileWed: "",
      photoVideos: [],
      returnPhotoValue: [],
      photoContentValue: "",
      photoCountNumber: 0,
      videoCountNumber: 0,
      hotelId: ""
    };
  },

  created() {
    this.getOneHotel();
  },

  methods: {
    getOneHotel() {
      this.hotelId = global.getHotel();

      service.getOneHotel(this.hotelId, data => {
        if (data.code === 200) {
          this.hotelInfo = data.data;
          this.images = this.hotelInfo.photos;
          this.hotelVideos = this.hotelInfo.videos;
          this.packgid = this.hotelInfo.package;
          this.coverImg = this.hotelInfo.coverImage;
          this.logoImg = this.hotelInfo.logoImage;
          this.bannerImg = this.hotelInfo.bannerImage;
          this.progressPage = this.hotelInfo.progressPage;
          this.wedImages = this.hotelInfo.weddingImage;
          service.getOnePackages({ id: this.packgid }, packagedata => {
            if (packagedata) {
              this.packageInfo.packages.features.content = packagedata.data.features[1].content;

              this.contentVI = this.packageInfo.packages.features.content;
              const str = this.contentVI.split("+");

              const icount = this.contentVI.split("P");

              this.imagecount = icount[0];
              const vcount = str[1].split("V");

              this.videocount = vcount[0];
            }
          });
          this.countPhotoVideos();
        } else {
          this.toaster.error("No Data Found");
        }
      });
    },
    countPhotoVideos() {
      service.getOneHotelWithPackage(data => {
        this.photoVideos = data.data.package.features;
        this.returnPhotoValue = this.photoVideos.filter(data => data.name.includes("Photo"));
        this.returnPhotoValue.forEach(data => {
          this.photoContentValue = data.content;
        });
        var x = this.photoContentValue.split("+");
        var y = x[0];
        var z = x[1];
        this.photoCountNumber = parseInt(y.substring(0, 2));
        this.videoCountNumber = parseInt(z.substring(0, 2));
      });
    },
    onchange(e) {
      const file = e.target.files[0];
      this.url = URL.createObjectURL(file);
      this.images.push({ link: this.url });
    },
    onCoverChange(coverName, coverList) {
      if (this.coverImg.length >= 1) {
        this.$toaster.error("Maximum limit of selection of  image is 1");
      } else {
        this.coverFile = coverList[0];

        const coverSize = this.coverFile.size / 1024;

        if (coverSize > 1000) {
          //cover ka 500kb
          this.errorDialog = true;
          this.errorText = "File Size should not be greater than 1mb.";
          this.$toaster.error("File Size should not be greater than 1mb.");
          return;
        }
        if (this.coverFile.type == "image/jpeg" || this.coverFile.type == "image/jpg") {
          const fd = new FormData();
          fd.append("file", this.coverFile, this.coverFile.name);

          service.awscoverImageUpload(fd, awsResponse => {
            if (awsResponse.status === 200) {
              const predictionResults = awsResponse.data;
              this.s3URL = predictionResults.file;
              this.coverImg.push(this.s3URL);
              this.detail.coverImage = this.coverImg;
            } else {
              this.$toaster.error("Cover Image Not Saved");
            }
          });
        } else {
          this.$toaster.error("Image should be in JPEG format only");
        }
      }
    },
    onlogoChange(logoName, logoList) {
      if (this.logoImg.length >= 1) {
        this.$toaster.error("Maximum limit of selection of  image is 1");
        return;
      }

      this.logoFile = logoList[0];

      const logoSize = this.logoFile.size / 1024;

      if (logoSize > 200) {
        //logo ka 100kb
        this.errorDialog = true;
        this.errorText = "File Size should not be greater than 200kb";
        this.$toaster.error("File Size should not be greater than 200kb");
        return;
      }
      if (this.logoFile.type == "image/jpeg" || this.logoFile.type == "image/jpg") {
        const fd = new FormData();
        fd.append("file", this.logoFile, this.logoFile.name);

        service.awscoverImageUpload(fd, awsResponse => {
          if (awsResponse.status === 200) {
            const predictionResults = awsResponse.data;
            this.s3URL = predictionResults.file;
            this.logoImg.push(this.s3URL);
            this.detail.logoImage = this.logoImg;
          } else {
            this.$toaster.error("Logo Image Not Saved");
          }
        });
      } else {
        this.$toaster.error("Image should be in JPEG format only");
      }
    },
    onBannerChange(bannerName, bannerList) {
      if (this.bannerImg.length >= 1) {
        this.$toaster.error("Maximum limit of selection of  image is 1");
        return;
      }

      this.bannerFile = bannerList[0];

      const bannerSize = this.bannerFile.size / 1024;

      if (bannerSize > 1000) {
        //banner 500kb
        this.errorDialog = true;
        this.errorText = "File Size should not be greater than 1mb";
        this.$toaster.error("File Size should not be greater than 1mb");
        return;
      }
      if (this.bannerFile.type == "image/jpeg" || this.bannerFile.type == "image/jpg") {
        const fd = new FormData();
        fd.append("file", this.bannerFile, this.bannerFile.name);

        service.awscoverImageUpload(fd, awsResponse => {
          if (awsResponse.status === 200) {
            const predictionResults = awsResponse.data;
            this.s3URL = predictionResults.file;
            this.bannerImg.push(this.s3URL);
            this.detail.bannerImage = this.bannerImg;
          } else {
            this.$toaster.error("Banner Image Not Saved");
          }
        });
      } else {
        this.$toaster.error("Image should be in JPEG format only");
      }
    },
    onVideoChange(videoName, videoList) {
      const videoArray = [];
      // Convert from fileList to array

      for (let i = 0; i < videoList.length; i++) {
        videoArray.push(videoList[i]);
      }

      const newVideos = videoArray.map((videoFile, index) => ({
        videoFile,
        videoURL: URL.createObjectURL(videoFile),
        name: videoFile.name,
        key: `${new Date().getTime()}-${index}`
      }));

      if (newVideos.length >= 1) {
        for (let i = 0; i < newVideos.length; i++) {
          this.videoUrl = newVideos[i].videoURL;
          this.videoName = newVideos[i].name;
          this.videoFile = newVideos[i].videoFile;

          const videoSize = this.videoFile.size / (1024 * 1024);

          if (videoSize > 100) {
            this.errorDialog = true;
            this.errorText = "File Size should not be greater than 100 Mb.";
            this.$toaster.error("File Size should not be greater than 100 Mb.");
          } else {
            this.onVideo();
          }
        }
      } else {
        this.errorDialog = true;
        this.errorText = "Maximum limit of selection of video file is " + this.videocount;
        this.$toaster.error("Maximum limit of selection of video file is " + this.videocount);
      }
    },
    onVideo() {
      this.disableVideoUpload = true;

      const fd = new FormData();
      fd.append("file", this.videoFile, this.videoFile.name);

      service.awsWeddingImageUpload(fd, awsResponse => {
        if (awsResponse.status === 200) {
          this.disableVideoUpload = false;
          const predictionResults = awsResponse.data;
          this.s3URL = predictionResults.file;

          if (this.hotelVideos.length >= this.videoCountNumber) {
            this.$toaster.error(
              "Maximum limit of selection of video file is" + this.videoCountNumber
            );
          } else {
            this.hotelVideos.push(this.s3URL);
            this.detail.videos = this.hotelVideos;
          }
        } else {
          this.disableVideoUpload = "false";
          this.$toaster.danger("Video Not Saved");
        }
      });
    },
    onFileChange(fieldName, fileList) {
      if (this.packgid == undefined) {
        this.$toaster.error("Package is not selected");
      }
      if (this.images.length >= this.photoCountNumber) {
        this.$toaster.error("Maximum limit of selection of  image is " + this.photoCountNumber);
        return;
      } else {
        const fileArray = [];
        // Convert from fileList to array

        for (let i = 0; i < fileList.length; i++) {
          fileArray.push(fileList[i]);
        }

        const newImages = fileArray.map((imageFile, index) => ({
          imageFile,
          imageURL: URL.createObjectURL(imageFile),
          name: imageFile.name,
          key: `${new Date().getTime()}-${index}`
        }));

        if (newImages.length <= this.imagecount) {
          for (let i = 0; i < newImages.length; i++) {
            this.imageUrl = newImages[i].imageURL;
            this.PhotoName = newImages[i].name;
            this.imageFile = newImages[i].imageFile;

            const imageSize = this.imageFile.size / 1024;

            if (imageSize > 1000) {
              //selected 500kb
              this.errorDialog = true;
              this.errorText = "File Size should not be greater than 1mb";
              this.$toaster.error("File Size should not be greater than 1mb");
              return;
            }

            if (this.imageFile.type == "image/jpeg" || this.imageFile.type == "image/jpg") {
              this.onImage();
            } else {
              this.$toaster.error("Image should be in JPEG format only");
              return;
            }
          }
        } else {
          this.errorDialog = true;
          this.errorText = "Maximum limit of selection of image file is 10.";
          this.$toaster.error("Maximum limit of selection of image file is 10");
        }
      }
    },
    onImage() {
      const fd = new FormData();
      fd.append("file", this.imageFile, this.imageFile.name);
      service.awsWeddingImageUpload(fd, awsResponse => {
        if (awsResponse.status === 200) {
          const predictionResults = awsResponse.data;
          this.s3URL = predictionResults.file;
          if (this.images.length >= this.photoCountNumber) {
            this.$toaster.error("Maximum limit of selection of  image is " + this.photoCountNumber);
          } else {
            this.images.push(this.s3URL);
            this.detail.photos = this.images;
          }
        } else {
          this.$toaster.danger("Image Not Saved");
        }
      });
    },
    // rea; wedding upload function
    onFileChangeWedding(fieldName, fileList) {
      if (this.packgid == undefined) {
        this.$toaster.error("Package is not selected");
      }

      if (this.wedImages.length >= this.photoCountNumber) {
        this.$toaster.error("Maximum limit of selection of  image is " + this.photoCountNumber);
        return;
      } else {
        const fileArray = [];
        for (let i = 0; i < fileList.length; i++) {
          fileArray.push(fileList[i]);
        }
        const newImages = fileArray.map((imageFile, index) => ({
          imageFile,
          imageURL: URL.createObjectURL(imageFile),
          name: imageFile.name,
          key: `${new Date().getTime()}-${index}`
        }));

        if (newImages.length <= this.imagecount) {
          for (let i = 0; i < newImages.length; i++) {
            this.imageUrlWed = newImages[i].imageURL;
            this.PhotoNameWed = newImages[i].name;
            this.imageFileWed = newImages[i].imageFile;
            const imageSizeWed = this.imageFileWed.size / 1024;

            if (imageSizeWed > 1000) {
              //selected 500kb
              this.errorDialog = true;
              this.errorText = "File Size should not be greater than 1mb";
              this.$toaster.error("File Size should not be greater than 1mb");
              return;
            }

            if (this.imageFileWed.type == "image/jpeg" || this.imageFileWed.type == "image/jpg") {
              this.onWeddingImage();
            } else {
              this.$toaster.error("Image should be in JPEG format only");
              return;
            }
          }
        } else {
          this.errorDialog = true;
          this.errorText = "Maximum limit of selection of image file is 10.";
          this.$toaster.error("Maximum limit of selection of image file is 10");
        }
      }
    },
    onWeddingImage() {
      const fd = new FormData();
      fd.append("file", this.imageFileWed, this.imageFileWed.name);
      service.awsRealWeddingImageUpload(fd, awsResponse => {
        if (awsResponse.status === 200) {
          const predictionResults = awsResponse.data;
          this.wedImagesURL = predictionResults.file;
          if (this.wedImages.length >= this.photoCountNumber) {
            this.$toaster.error("Maximum limit of selection of  image is " + this.photoCountNumber);
          } else {
            this.wedImages.push(this.wedImagesURL);
            this.detail.weddingImage = this.wedImages;
          }
        } else {
          this.$toaster.danger("Image Not Saved");
        }
      });
    },
    removeWeddingImages(index) {
      this.wedImages.splice(index, 1);
    },
    remove(index) {
      this.images.splice(index, 1);
    },
    removeCover(image) {
      this.coverImg.pop(image);
    },
    removeLogo(image) {
      this.logoImg.pop(image);
    },
    removeBanner(image) {
      this.bannerImg.pop(image);
    },
    removeVideo(index) {
      this.hotelVideos.splice(index, 1);
    },
    saveData() {
      if (
        this.images.length == 0 ||
        this.coverImg.length == 0 ||
        this.logoImg.length == 0 ||
        this.bannerImg.length == 0
      ) {
        this.$toaster.error("Upload all mandatory images/videos");
        return;
      }

      if (this.images.length < 5) {
        this.$toaster.error("Minimun 5 Images Required In Other Images");
        return;
      }

      return new Promise((resolve, reject) => {
        this.detail.photos = this.images;
        this.detail.videos = this.hotelVideos;
        this.detail.coverImage = this.coverImg;
        this.detail.logoImage = this.logoImg;
        this.detail.bannerImage = this.bannerImg;
        this.detail.weddingImage = this.wedImages;

        if (this.hotelInfo.progressCompleted == true) {
          this.detail.progressPage = "covid";
        } else {
          this.detail.progressPage = "images";
        }

        service.updateHotelAdmin(this.detail, this.hotelId, saveResult => {
          if (saveResult.status === 200) {
            resolve();
            this.$toaster.success("Saved Successfully");
            this.showButton = true;
          } else {
            reject();
            this.$toaster.error("Not saved");
          }
        });
      });
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/scss/variables";
.image-details {
  .image-details-section {
    .image-selection {
      display: flex;
    }
    .video-selection {
      display: flex;
    }
    .card {
      border: none;
    }
  }
}
</style>
