/* eslint-disable no-underscore-dangle */ /* eslint-disable no-console */
<template>
  <div>
    <headerCustom></headerCustom>
    <div class="template main-content sidenav-part ng-scope">
      <div class="d-flex">
        <div class="mx-3">
          <div class="heading-sec">
            <div class="row">
              <div class="heading-profile d-flex justify-content-between">
                <h3>Hotel</h3>
                <div class="col-md-6 msg">
                  <b-button class="btn-danger" to="/hotel-message"
                    >Send Message from WedDestination</b-button
                  >
                </div>
                <div>
                  <b-form-group>
                    <label class="font-18">Status</label>
                    <b-form-select
                      @input="saveData(hotelDetail.isVerified)"
                      v-model="hotelDetail.isVerified"
                      :options="options"
                    ></b-form-select>
                  </b-form-group>
                </div>
              </div>
            </div>
            <div class="row float-right">
              <div class="d-flex">
                <div>
                  <b-form-checkbox
                    v-model="status1"
                    value="accepted"
                    unchecked-value="not_accepted"
                    @input="updateSimplyDestination(status1)"
                    >Trending Properties</b-form-checkbox
                  >
                </div>
                <div class="ml-3">
                  <b-form-checkbox
                    v-model="status2"
                    value="accepted"
                    unchecked-value="not_accepted"
                    @input="updateHotNTrend(status2)"
                    >Debutants</b-form-checkbox
                  >
                </div>
                <div class="mx-3">
                  <b-form-checkbox
                    v-model="status3"
                    value="accepted"
                    unchecked-value="not_accepted"
                    @input="updateTrending(status3)"
                    >Trending</b-form-checkbox
                  >
                </div>
                <div class="mx-3">
                  <b-form-checkbox
                    v-model="status"
                    value="accepted"
                    unchecked-value="not_accepted"
                    @input="updateExclusive(status)"
                    >Is Exclusive</b-form-checkbox
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- Top Bar Chart -->
          <div class="panel-content">
            <div class="widget">
              <div class="main-hotel-content">
                <div class="hotel-content">
                  <div id="hotelBox" class="hotel-section">
                    <div class="hotel-tab-section py-2">
                      <div
                        v-for="(tabContent, index) in tabList"
                        :key="tabContent.index"
                        class="font-10"
                        :class="{ 'tab-color': currentTab == index }"
                        @click="mytab(index)"
                      >
                        {{ tabContent.tabName }}
                        <span class="mx-1">
                          <font-awesome-icon
                            :icon="tabContent.icon"
                            :class="{ 'arrow-next': currentTab == index }"
                          ></font-awesome-icon>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="about-section mx-3">
                    <welcome v-if="currentTab == 0">
                      <welcome />
                    </welcome>
                    <room v-if="currentTab == 1">
                      <room />
                    </room>
                    <venues v-if="currentTab == 2">
                      <venues />
                    </venues>
                    <restaurant v-if="currentTab == 3">
                      <restaurant />
                    </restaurant>
                    <facilites v-if="currentTab == 4">
                      <facilites />
                    </facilites>
                    <menus v-if="currentTab == 5">
                      <menus />
                    </menus>
                    <inclusions v-if="currentTab == 6">
                      <inclusions />
                    </inclusions>
                    <policies v-if="currentTab == 7">
                      <policies />
                    </policies>
                    <!-- <faq  v-if="currentTab == 8"></faq> -->
                    <contact v-if="currentTab == 8">
                      <contact />
                    </contact>
                    <imageSection v-if="currentTab == 9">
                      <imageSection />
                    </imageSection>
                    <covid v-if="currentTab == 10">
                      <covid />
                    </covid>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <sideMenu :navigations="navigation" />
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import headerCustom from "@/components/Header.vue";
import sideMenu from "@/components/subheader.vue";
import welcome from "@/components/hotel/welcome.vue";
import room from "@/components/hotel/room.vue";
import venues from "@/components/hotel/venues.vue";
import restaurant from "@/components/hotel/restaurant.vue";
import facilites from "@/components/hotel/facilities.vue";
import menus from "@/components/hotel/menuInfo.vue";
import inclusions from "@/components/hotel/inclusions.vue";
import imageSection from "@/components/hotel/image-video.vue";
import policies from "@/components/hotel/policies.vue";
import contact from "@/components/hotel/contact.vue";
import covid from "@/components/hotel/covid.vue";
import faq from "@/components/hotel/faq.vue";
import service from "@/service/apiservice";
import global from "@/service/global";

export default {
  components: {
    headerCustom,
    sideMenu,
    welcome,
    room,
    venues,
    restaurant,
    menus,
    facilites,
    inclusions,
    policies,
    imageSection,
    contact,
    covid
    // faq
  },
  data() {
    return {
      exclusiveDetails: "",
      statusDetails: "",
      simplyDestinationDetails: "",
      hotNTrendDetails: "",
      trendingDetails: "",
      hotelDetail: {
        isVerified: "",
        isExclusive: ""
      },
      status: "not_accepted",
      status1: "not_accepted",
      status2: "not_accepted",
      status3: "not_accepted",
      afterChange: {},
      checkedNames: false,
      navigation: {},

      options: [
        {
          text: "Approve",
          value: "true"
        },
        {
          text: "Reject",
          value: "false"
        },
        {
          text: "Pending",
          value: "pending"
        }
      ],
      menu: [
        {
          name: "Info",
          path: "/info-details/"
        },
        {
          name: "hotel details",
          path: `/hotel/`
        },
        {
          name: "Transaction",
          path: "/transaction-details/"
        }
      ],
      currentTab: 0,
      tabList: [
        {
          tabName: "About",
          icon: "arrow-right"
        },
        {
          tabName: "Rooms & Amenities",
          icon: "arrow-right"
        },
        {
          tabName: "Venues",
          icon: "arrow-right"
        },
        {
          tabName: "Restaurants",
          icon: "arrow-right"
        },
        // {
        //     tabName: 'Amenities',
        //     icon: 'arrow-right',
        // },
        {
          tabName: "Facilities",
          icon: "arrow-right"
        },
        {
          tabName: "Menus",
          icon: "arrow-right"
        },
        {
          tabName: "Inclusions",
          icon: "arrow-right"
        },
        {
          tabName: "Policies",
          icon: "arrow-right"
        },
        // {
        //   // eslint-disable-next-line quotes
        //   tabName: "FAQ's"
        // },

        {
          tabName: "Contact",
          icon: "arrow-right"
        },

        {
          tabName: "Images",
          icon: "arrow-right"
        },
        {
          tabName: "Covid"
        }
      ],
      // this is use for menu table
      contents: [
        {
          categories: "breakfast (Breakfast Is Complementary)",
          menuNames: [
            {
              name: "Moctails/Juice"
            },
            {
              name: "Appetizers"
            },
            {
              name: "Soups"
            },
            {
              name: "Breads"
            },
            {
              name: "Salads/Raita"
            },
            {
              name: "Main Course"
            },
            {
              name: "Rice"
            }
          ]
        },
        {
          categories: "Lunch",
          menuNames: [
            {
              name: "Moctails/Juice"
            },
            {
              name: "Appetizers"
            },
            {
              name: "Soups"
            },
            {
              name: "Breads"
            },
            {
              name: "Salads/Raita"
            },
            {
              name: "Main Course"
            },
            {
              name: "Rice"
            }
          ]
        },
        {
          categories: "Hi-Tea:",
          menuNames: [
            {
              name: "Moctails/Juice"
            },
            {
              name: "Appetizers"
            },
            {
              name: "Soups"
            },
            {
              name: "Breads"
            },
            {
              name: "Salads/Raita"
            },
            {
              name: "Main Course"
            },
            {
              name: "Rice"
            }
          ]
        },
        {
          categories: "Dinner",
          menuNames: [
            {
              name: "Moctails/Juice"
            },
            {
              name: "Appetizers"
            },
            {
              name: "Soups"
            },
            {
              name: "Breads"
            },
            {
              name: "Salads/Raita"
            },
            {
              name: "Main Course"
            },
            {
              name: "Rice"
            }
          ]
        },
        {
          categories: "Supper / Mid Night Meal",
          menuNames: [
            {
              name: "Moctails/Juice"
            },
            {
              name: "Appetizers"
            },
            {
              name: "Soups"
            },
            {
              name: "Breads"
            },
            {
              name: "Salads/Raita"
            },
            {
              name: "Main Course"
            },
            {
              name: "Rice"
            }
          ]
        }
      ]
      // id: '',
    };
  },
  created() {
    this.navigation._id = this.$route.params.id;
    this.navigation.menu = this.menu;
    this.getOneHotel();
  },
  methods: {
    mytab(tabNo) {
      this.currentTab = tabNo;
     
    },
    nextTab(tabNo) {
     
      this.currentTab = tabNo + 1;
    },
    getOneHotel() {
      const hotelId = this.$route.params.id;
      global.setHotel(hotelId);
      service.getOneHotel(hotelId, data => {
        if (data.code === 200) {
          this.hotelDetail = data.data;
       
          this.statusDetails = this.hotelDetail.isVerified;

          if (this.hotelDetail.isExclusive) {
            this.status = "accepted";
          } else {
            this.status = "not_accepted";
          }
          this.exclusiveDetails = this.status;

          if (this.hotelDetail.simplyDestination) {
            this.status1 = "accepted";
          } else {
            this.status1 = "not_accepted";
          }
          this.simplyDestinationDetails = this.status1;

          if (this.hotelDetail.hotNTrend) {
            this.status2 = "accepted";
          } else {
            this.status2 = "not_accepted";
          }
          this.hotNTrendDetails = this.status2;

          if (this.hotelDetail.trending) {
            this.status3 = "accepted";
          } else {
            this.status3 = "not_accepted";
          }
          this.trendingDetails = this.status3;
          this.hotelDetail.languageSpoken = _.chain(this.hotelDetail.languageSpoken)
            .map("language")
            .join(", ")
            .value();
          this.hotelDetail.hotelType = this.hotelDetail.hotelType.map(x => x.name).join(", ");

          // this.hotelDetail.languageSpoken = _.join(this.hotelDetail.languageSpoken, ",");
          this.hotelDetail.amenities.roomAmenities = this.hotelDetail.amenities.roomAmenities
            .map(x => x.name)
            .join(", ");
          this.hotelDetail.amenities.bathroomAmenities = this.hotelDetail.amenities.bathroomAmenities
            .map(x => x.name)
            .join(", ");
          this.hotelDetail.inclusions.basic = this.hotelDetail.inclusions.basic
            .map(x => x.name)
            .join(", ");
          this.hotelDetail.inclusions.special = this.hotelDetail.inclusions.special
            .map(x => x.name)
            .join(", ");
          this.hotelDetail.rooms.accommodationTypes = this.hotelDetail.rooms.accommodationTypes
            .map(x => x.name)
            .join(", ");
        }
      });
    },
    updateExclusive(exclusiveData) {
   
      if (this.exclusiveDetails != exclusiveData) {
      
        const hotelId = this.$route.params.id;
        if (this.status === "accepted") {
          this.hotelDetail.isExclusive = true;
        } else {
          this.hotelDetail.isExclusive = false;
        }
        const dataToUpdate = {
          isExclusive: this.hotelDetail.isExclusive,
          id: hotelId
        };
      
        service.updateExclusive(dataToUpdate, result => {
          this.$toaster.success("Exclusive Updated");
        });
      }
    },
    saveData(statusData) {
      if (this.statusDetails != statusData) {
        const hotelId = this.$route.params.id;
        const hotelVerified = {
          isVerified: this.hotelDetail.isVerified
        };
        service.updateHotel(hotelVerified, hotelId, result => {
          this.$toaster.success("Status Updated");
        });
      }
    },
    updateSimplyDestination(simplyData) {
      if (this.simplyDestinationDetails != simplyData) {
        const hotelId = this.$route.params.id;
        if (this.status1 === "accepted") {
          this.hotelDetail.simplyDestination = true;
        } else {
          this.hotelDetail.simplyDestination = false;
        }
        const dataToUpdate = {
          simplyDestination: this.hotelDetail.simplyDestination,
          id: hotelId
        };
        service.updateSimplyDestination(dataToUpdate, result => {
          this.$toaster.success("Simply Destination Updated");
        });
      }
    },
    updateHotNTrend(hotNTrendData) {
      if (this.hotNTrendDetails != hotNTrendData) {
        const hotelId = this.$route.params.id;
        if (this.status2 === "accepted") {
          this.hotelDetail.hotNTrend = true;
        } else {
          this.hotelDetail.hotNTrend = false;
        }
        const dataToUpdate = {
          hotNTrend: this.hotelDetail.hotNTrend,
          id: hotelId
        };
        service.updateHotNTrend(dataToUpdate, result => {
          this.$toaster.success("Hot and Trend Updated");
        });
      }
    },
    updateTrending(trendingData) {
      if (this.trendingDetails != trendingData) {
        const hotelId = this.$route.params.id;
        if (this.status3 === "accepted") {
          this.hotelDetail.trending = true;
        } else {
          this.hotelDetail.trending = false;
        }
        const dataToUpdate = {
          trending: this.hotelDetail.trending,
          id: hotelId
        };
        service.updateTrending(dataToUpdate, result => {
          this.$toaster.success("Trending Updated");
        });
      }
    }
  }
};
</script>
<style lang="scss">
@import "src/assets/scss/variables";
.main-hotel-content {
  // background: $active-grey;
  .hotel-content {
    // .hotel-section {
    //   overflow: auto;
    // }
    .font-10 {
      font-size: 14px;
    }
    .hotel-section {
      margin: 30px 10px; // margin: 30px 20px;
      .hotel-tab-section {
        display: flex;
        cursor: pointer;
      }
    }
    .arrow-next {
      color: $black;
    }
    .tab-color {
      color: $pink;
    }
    .about-section {
      padding: 2rem;
    }
    .btn-section {
      .continue-btn {
        padding: 5px 90px;
      }
      .btn-warning {
        color: $white;
        background-color: $pink;
        border-color: $pink;
      }
      .btn-warning:focus {
        color: $white;
        background-color: $pink;
        border-color: $pink;
        box-shadow: none;
      }
    }
  }
}
</style>
